import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '@/features/Session/sessionSlice'

import {
    changeStaffForCheck,
    closeModal,
    fetchUsers,
    selectComponent,
    selectHasVirtualKeyboard,
    selectUsers,
    selectModals,
    setUsers,
    fetchChecks,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import ModalListButton from '@/features/AdvancedPointOfSale/components/buttons/ModalListButton'
import Search from '@/features/AdvancedPointOfSale/components/Search'
import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'

export default function ChangeStaffModal({ minSearchableStaff=2, className='change-staff-modal' }) {

    const dispatch              = useDispatch()
    const component             = useSelector(selectComponent)
    const { changeStaff:modal } = useSelector(selectModals)
    const currentUser           = useSelector(selectCurrentUser)
    const users                 = useSelector(selectUsers)
    const hasVirtualKeyboard    = useSelector(selectHasVirtualKeyboard)

    const [searchInput, setSearchInput]                = useState('')
    const [selectedStaffId, setSelectedStaffId]        = useState(null)
    const [currentResults, setCurrentResults]          = useState([])
    const [verticallyCentered, shouldVerticallyCenter] = useState(true)

    const handleSearch = e => {
        const text = e.target.value

        setSearchInput(text)

        if (text === '') {
            setCurrentResults(users)
        } else {
            const filteredStaffs = [...users].filter(user => (
                user.name.toLowerCase().includes(text.toLowerCase())
            ))

            setCurrentResults(filteredStaffs || [])
        }
    }

    const handleClose = () => {
        setSearchInput('')
        setSelectedStaffId(null)
        setCurrentResults([])
        dispatch(closeModal('changeStaff'))
    }

    const handleChange = (checkId) => {
        dispatch(changeStaffForCheck(checkId, selectedStaffId)).then(() => {
            if (/^(OpenChecks)$/i.test(component)) {
                dispatch(fetchChecks(currentUser.id, {
                    include_closed: true
                }))
            }

            if (/^(EndOfShift)$/i.test(component)) {
                dispatch(fetchChecks(currentUser.id, {
                    include_closed: true,
                    include_payments: true,
                }))
            }

            if (/^(CloseOfDay)$/i.test(component)) {
                dispatch(fetchChecks(null, {
                    include_closed: true,
                    include_payments: true,
                    include_searchable_cards: true,
                }))
            }

            handleClose()
        })
    }

    const handleKeyDown = e => {
        e.stopPropagation()

        if (e.key === 'Escape') { handleClose() }
    }

    useEffect(() => {
        if (modal.isOpen) {
            document.addEventListener('keydown', handleKeyDown, false)

            dispatch(fetchUsers()).then(data => {
                if (data.find((user) => user.id === currentUser.id)) {
                    setCurrentResults(data)
                } else {
                    const updatedData = [...data]
                    updatedData.push(currentUser)
                    setCurrentResults(updatedData)
                    dispatch(setUsers(updatedData))
                }
            })
        } else {
            document.removeEventListener('keydown', handleKeyDown, false)
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [modal.isOpen])

    const footerButtons = <>
        <button
            type='button'
            className='btn btn-outline-secondary text-white text-bold mr-auto'
            children='Close'
            onClick={handleClose}
        />
        <button
            children="Change"
            className="btn btn-primary ml-auto"
            disabled={(selectedStaffId === null) || (currentResults === 0)}
            onClick={() => handleChange(modal.check.id)}
        />
    </>

    return modal.isOpen && !!modal.check && (
        <Modal
            isOpen={modal.isOpen && !!modal.check}
            verticallyCenter={verticallyCentered}
            title='Change Staff'
            size={modal.size}
            footerButtons={footerButtons}
            onClose={handleClose}
            className={className}
        >
            {
                (searchInput.length > 0 || (currentResults.length > minSearchableStaff)) && (
                    <Search
                        className='w-100 mx-auto mt-3 px-4'
                        placeholder='Filter by Name'
                        searchInput={searchInput}
                        onSearch={handleSearch}
                        onFocus={() => { if (hasVirtualKeyboard) { shouldVerticallyCenter(false) }}}
                        onBlur={() => { if (hasVirtualKeyboard) { window.setTimeout(() => shouldVerticallyCenter(true), 200) }}}
                    />
                )
            }

            {
                currentResults.length > 0 ? (
                    <div className='staff-list my-3 mx-3 px-1'>
                        {
                            [...currentResults]
                                .sort((a,b) => (a?.name > b?.name) ? 1 : -1)
                                .map(user => (
                                    <ModalListButton
                                        key={user.id}
                                        children={user.name}
                                        selected={user.id === selectedStaffId}
                                        disabled={(user.id == modal.check.server.id)}
                                        onClick={() => setSelectedStaffId(user.id)}
                                    />
                                ))
                        }
                    </div>
                ) : (
                    <CenteredText text='No Staff Found' className='py-5' textClassName='text-gray3' />
                )
            }
        </Modal>
    )
}
